@import '../../../../variables';

.leadership-middle-item {
  background-color: $white;
  display: flex;
  padding: 100px;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    padding: 0;
  }

  .leadership-middle-item-text {
    flex: 1;
    padding: 0 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 992px) {
      padding: 30px 48px;
    }

    .leadership-middle-item-text-description {
      margin-top: 14px;
    }
  }

  .leadership-middle-item-image {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../../../assets/images/elearning-coach.png');
    height: 300px;
    padding: 0 56px;
    @media (max-width: 992px) {
      padding: 0;
      height: 200px;
      margin-top: 40px;
    }
  }
}
