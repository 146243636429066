@import '../../variables';

.supportPart {
  background: #fbfbfa;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleWrapper {
    margin-top: 60px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .supportIcon-box {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  .itemSupportWrapper {
    cursor: pointer;
    width: 286px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: auto;
      margin-right: 24px;
      margin-left: 10px;
    }
  }

  .support-part-links-container {
    display: flex;

    > a {
      text-decoration: none;
    }
  }
}
