@import '../../variables';

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  text-align: center;
  border: none;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.66;
  }

  .spinner-container {
    margin-right: 14px;
  }

  &__text {
    margin-right: 15px;
    padding-top: 2px;
  }

  &_text_back {
    padding-top: 3px;
  }

  &--main {
    border: 1px solid $yellow;
    background: $yellow;
    color: $main;

    &:hover {
      color: $main;
      background: darken($yellow, 10%);
    }
  }

  &--secondary {
    border: 1px solid $blue;
    color: $white;
    background: $blue;

    &:hover {
      color: $white;
      background: darken($blue, 10%);
    }
  }

  &--green {
    border: 1px solid $green;
    background: $green;
    color: $white;

    &:hover {
      color: $white;
      background: darken($green, 10%);
    }
  }

  &--light {
    border: 1px solid $light6;
    background: transparent;
    color: $main;
    box-shadow: none;

    &:hover {
      color: $main;
      background: darken($white, 10%);
    }
  }

  &--main-dark {
    border: 1px solid $dark-yellow;
    color: $white;
    background: $dark-yellow;

    &:hover {
      color: $white;
      background: darken($dark-yellow, 10%);
    }
  }

  &--red {
    border: 1px solid $red;
    color: $white;
    background: $red;

    &:hover {
      color: $white;
      background: darken($red, 10%);
    }
  }

  &--dark-orchid {
    border: 1px solid $dark-orchid;
    color: $white;
    background: $dark-orchid;

    &:hover {
      color: $white;
      background: darken($dark-orchid, 10%);
    }
  }

  &--big {
    padding: 19px 40px;
    svg {
      position: relative;
      left: 15px;
    }
    @media (max-width: 992px) {
      padding: 14px 28px;
    }
  }

  &--medium {
    padding: 14px 32px;
    svg {
      position: relative;
      left: 15px;
    }
    span {
      position: relative;
      top: 1px;
    }
    @media (max-width: 992px) {
      padding: 10.5px 20px;
      span {
        top: 0px;
      }
    }
  }

  &--small {
    padding: 14px 24px;
    svg {
      position: relative;
      left: 15px;
    }
    @media (max-width: 992px) {
      padding: 11px 16px;
    }
  }
}
