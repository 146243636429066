@import '../../variables';

.delete-product-modal {
  .modal-header {
    padding-left: 110px;
  }
  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .delete-product-modal-text {
      margin-bottom: 48px;
      text-align: center;
    }
  }
}
