@import '../../variables';

.find-workshops-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 56px;
  margin-left: -24px;
}

.find-workshops-item {
  background: white;
  padding-bottom: 27px;
  .find-workshops-item-title {
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      padding: 40px 20px;
    }
  }
}
.find-workshops-link {
  &:hover {
    text-decoration: none;
  }
}
