@import '../../../../variables';

.admin-footer {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  background-color: $white;

  .admin-footer-buttons-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
