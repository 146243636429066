@import "../../variables";

.container.container-with-margins{
  .row.white{
    background: white;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 0 0 0;

    .col {
      padding: 0;
    }

    .col.col-with-margin {
      padding-top: 30px;
      margin-bottom: 100px;
      background: white;
    }
  }
}
