@import '../../../variables.scss';

.description-container {
  width: 40%;
}

.description-title-container {
  display: flex;
  margin-bottom: 18px;
}

.description-title-place-or-status {
  display: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: $light2;

  @media (max-width: 992px) {
    display: block;
    text-transform: none;
  }
}

.description-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: $main-dark;
  width: 210px;
}

.description-check {
  width: 42px;
  height: 42px;
  @media (max-width: 992px) {
    width: 15px;
    height: 15px;
  }
}

.description-status-container {
  display: flex;
  align-items: center;
}
.description-text-status {
  margin-left: 8px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
  }
}

.description-title-place-container {
  @media (max-width: 992px) {
    align-items: center;
  }
}

.description-text {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: $main;
  margin-right: 90px;
  max-width: 350px;

  > p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 992px) {
    margin-right: 0;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    width: 165%;
  }
}

.description-place-and-icon {
  display: none;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 992px) {
    display: flex;
  }
}
