@import '../../variables';

.teaser {
  padding-left: 20px;
  padding-top: 20px;
  width: 320px;
  height: 210px;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    width: 230px;
    height: 155px;
  }

  &__main {
    background: white;
    width: 512px;
    height: 190px;
    padding: 40px 56px;
    @media (max-width: 992px) {
      padding: 24px 32px;
      width: 274px;
      height: 164px;
    }
  }

  &__title {
    margin-bottom: 8px;
  }
}
