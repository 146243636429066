@import '../../../../variables.scss';

.moderator-finder-top-item-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: 992px) {
    margin-left: -8px;
  }
}
