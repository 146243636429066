@import '../../variables.scss';

.impressum {
  background-color: white;

  .impressum-title-wrapper {
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impressum-main-container {
    padding-bottom: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 992px) {
      flex-direction: column;
    }

    .impressum-main-container-text {
      padding: 20px;
      width: 540px;
      max-width: 540px;
      @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
