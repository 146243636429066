@import '../../variables';

.dashboard-item {
  &-hidden {
    @extend .dashboard-item;
    @media (min-width: 883px) {
      display: none;
    }
  }
  margin-top: 16px;
  width: 100%;
  height: 396px;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 64px;
  background-repeat: no-repeat;
  justify-content: space-between;

  @media (max-width: $xs) {
    height: auto;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 28px;
  }

  @media (max-width: 992px) {
    background-size: cover;
  }

  .dashboard-item-content-wrapper {
    width: 380px;
    height: auto;
    padding-left: 26px;
    padding-top: 26px;
    background-repeat: no-repeat;
    @media (max-width: $xs) {
      width: 280px;
      padding-left: 16px;
      padding-top: 16px;
      background-size: contain;
    }
  }

  .dashboard-item-content {
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    padding: 36px 44px;
    @media (max-width: $xs) {
      padding: 24px 32px;
    }
  }

  .dashboard-item-title {
    margin-bottom: 16px;
  }

  #dashboard-btn {
    width: auto;
    display: flex;
    align-items: center;
    align-self: center;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    @media (max-width: $xs) {
      padding: 15px 30px;
      margin-top: 14px;
    }
  }
}

.dashboard-item-large {
  @media (max-width: 882px) {
    display: none;
  }
  margin-top: 16px;
  width: 100%;
  height: 255px;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 64px;
  background-repeat: no-repeat;
  justify-content: space-between;

  @media (max-width: $xs) {
    height: auto;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 28px;
  }

  @media (max-width: 992px) {
    background-size: cover;
  }

  .dashboard-item-content-wrapper {
    width: 403px;
    height: 160px;
    padding-left: 20px;
    padding-top: 20px;
    background-repeat: no-repeat;
    @media (max-width: $xs) {
      width: 280px;
      padding-left: 16px;
      padding-top: 16px;
      background-size: contain;
    }
  }

  .dashboard-item-content {
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    padding: 36px 32px;
    @media (max-width: $xs) {
      padding: 24px 32px;
    }
  }

  .dashboard-item-title {
    margin-bottom: 16px;
  }

  #dashboard-btn {
    width: auto;
    display: flex;
    align-items: center;
    align-self: center;
    bottom: 46px;
    right: 128px;
    position: absolute;
    text-decoration: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    @media (max-width: $xs) {
      padding: 15px 30px;
      margin-top: 14px;
    }
  }
}

.dashboard-support-part {
  width: 100% !important;
  .titleWrapper {
    margin-top: 40px;
  }
}

.news-list-col {
  background: white;
  padding-right: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  @media (max-width: $md) {
    padding-left: 0;
  }
}

.row-margin {
  margin-top: 32px;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: $md) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.support-part-col {
  padding-left: 0;
  background: #fbfbfa;
}
