@import '../../variables.scss';

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 992px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
