@import '../../../../variables';

.admin-products-new-form {
  background-color: $white;
  margin-top: 24px;
  padding: 37px 40px 44px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .admin-products-new-form-left-bar {
    display: flex;
    flex-direction: column;

    .admin-products-new-form-left-bar-language {
      margin: 29px 0 20px 0;
    }

    .admin-products-new-form-left-bar-language-switcher {
      display: flex;
      align-items: center;
      list-style: none;

      .admin-products-new-form-left-bar-language-switcher {
        position: relative;
        border-bottom: 2px solid $white;
        margin: 0 7.5px;
        cursor: pointer;

        &:hover, &.active {
          border-bottom: 2px $yellow solid;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:not(:last-of-type) {
          &::after {
            content: '';
            position: absolute;
            height: 17px;
            width: 1px;
            background-color: $light3;
            right: -8px;
          }
        }
      }

      .admin-products-new-form-left-bar-language-switcher-divider {
        position: absolute;
        height: 17px;
        width: 1px;
        background-color: $light3;
      }
    }

    .admin-products-new-form-left-bar-error-message {
      margin-top: 23px;
      width: 238px;
    }
  }

  .admin-products-new-form-container {
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    .admin-products-new-form-container-data {
      width: 100%;

      .admin-products-new-form-container-data-language-label {
        margin-left: 10px;
      }

      .admin-products-new-form-data-textarea {
        min-height: 120px;
        max-height: 120px;
      }
    }

    .admin-products-new-form-data-container-button-wrapper {
      width: 800px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      > hr {
        margin: 70px 0 57px 0;
        width: 100%;
      }
    }
  }
}
