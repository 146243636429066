@import '../../variables';

.admin-project-main-container {
  margin-top: 24px;
  background: $white;
  width: 100%;
  padding: 44px 40px;

  .admin-project-candidate-title {
    margin-top: 80px;
  }

  .admin-project-candidate-table {
    width: 100%;
    margin-top: 30px;

    .admin-project-candidate-table-head-row {
      border-bottom: 1px solid $light6;
      th {
        padding-bottom: 13px;
      }
    }

    .admin-project-candidate-table-body-row {
      border-bottom: 1px solid $light6;
      td {
        padding: 21px 0;
      }

      .admin-project-candidate-table-body-row-td {
        text-decoration: underline;
      }
    }
  }

  .admin-project-coaches-title {
    margin-top: 70px;
  }

  .admin-project-coaches-table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 80px;

    .admin-project-coaches-table-head-row {
      border-bottom: 1px solid $light6;
      th {
        padding-bottom: 13px;
      }
    }

    .admin-project-coaches-table-body-row {
      border-bottom: 1px solid $light6;
      td {
        padding: 21px 0;
      }

      .admin-project-coaches-table-body-row-td {
        text-decoration: underline;
      }
    }
  }
}
