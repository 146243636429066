@import '../../variables.scss';

.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
  align-items: center;
}

.nav-link {
  &__selected {
    &.yellow {
      .custom-nav-item .nav-item-text {
        border-bottom-color: $yellow;
      }
    }

    &.dark-yellow {
      .custom-nav-item .nav-item-text {
        border-bottom-color: $dark-yellow;
      }
    }

    &.blue {
      .custom-nav-item .nav-item-text {
        border-bottom-color: $blue;
      }
    }

    &.green {
      .custom-nav-item .nav-item-text {
        border-bottom-color: $green;
      }
    }

    &.dark-orchid {
      .custom-nav-item .nav-item-text {
        border-bottom-color: $dark-orchid;
      }
    }
  }

  &.yellow:hover {
    .custom-nav-item .nav-item-text {
      border-bottom-color: $yellow;
    }
  }

  &.dark-yellow:hover {
    .custom-nav-item .nav-item-text {
      border-bottom-color: $dark-yellow;
    }
  }

  &.blue:hover {
    .custom-nav-item .nav-item-text {
      border-bottom-color: $blue;
    }
  }

  &.green:hover {
    .custom-nav-item .nav-item-text {
      border-bottom-color: $green;
    }
  }

  &.dark-orchid:hover {
    .custom-nav-item .nav-item-text {
      border-bottom-color: $dark-orchid;
    }
  }

  :hover {
    text-decoration: none;
  }
}

.dropdown-toggle {
  &:before {
    margin-right: 6px;
  }
}

.nav-item-counter {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: $dark-yellow;
  text-align: center;
  padding-top: 5px;
}