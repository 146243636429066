//colors
$green: #89c959;
$light-yellow: #f8e78f;
$yellow: #f4da55;
$dark-yellow: #f8ae60;
$main: #666666;
$main-dark: #2a2d41;
$white: #fff;
$blue: #5dc0e0;
$dark-blue: #0056b3;
$red: #d95959;
$dark-orchid: #9046cf;
$light: #f7f7f7;
$light2: #afafaf;
$light3: #e3e3e3;
$light4: #fbfbfa;
$light5: #f2f2f2;
$light6: #cecece;

// breakpoints
$xs: 480px;
$md: 768px;
$lg: 1024px;
