@import '../../variables.scss';

.coach-moderator-buchen-modal-coach-moderator-main-info {
  display: flex;
  align-items: center;
  justify-content: center;

  .coach-moderator-buchen-modal-coach-moderator-main-info-text {
    padding: 15px 15px 15px 20px;
    @media (max-width: 992px) {
      padding: 15px;
    }
  }
}

.coach-moderator-buchen-modal-coach-moderator-middle-container {
  padding: 32px 60px 0 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .coach-moderator-buchen-modal-coach-moderator-middle-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px $light5 solid;
    width: 200px;
    height: 120px;
    @media (max-width: 992px) {
      width: 163px;
      height: 87px;
    }

    .coach-moderator-buchen-modal-coach-moderator-middle-container-item-description {
      padding-top: 12px;
    }
  }
}

.coach-moderator-buchen-modal-coach-moderator-description {
  padding: 32px 90px;
  text-align: center;
  @media (max-width: 992px) {
    padding: 24px 16px;
  }
}

.coach-moderator-buchen-modal-coach-moderator-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
