@import '../../../variables';

.book-offer-image {
  width: 104px;
  height: 104px;
  border-radius: 100%;
  margin-right: 24px;
  @media (max-width: 992px) {
    width: 72px;
    height: 72px;
    margin-right: 16px;
  }
}
.book-offer-hr {
  width: 100%;
  border-width: 3px;
  margin-top: -15px;
  @media (max-width: 992px) {
    display: none;
  }
}

.book-offer-image-info-container {
  width: 100%;
  height: 104px;
  margin: 0 74px;
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    margin: 0 36px;
  }
}

.book-offer-title {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $main;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}
.book-offer-title-2 {
  font-family: 'ClanOT-Medium';
  color: $main-dark;
}

.book-offer-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @media (max-width: 992px) {
    padding: 0 16px;
  }
}

.book-offer-info-container {
  min-height: 150px;
  border: 1px solid $light5;

  @media (max-width: 992px) {
    min-height: 120px;
  }
}

.book-offer-name {
  font-family: 'ClanOT-Medium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: $main-dark;
  margin-top: 33px;
  margin-bottom: 12px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.book-offer-value {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $main;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}
.book-offer-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  justify-content: space-between;
  @media (max-width: 992px) {
    height: 60px;
  }
}
.book-offer-button {
  height: 44px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-family: 'ClanOT-Medium';
  color: white;
  border: 1px solid $dark-orchid;
  background-color: $dark-orchid;
  margin: 15px 10px;
  padding: 14px 32px;
  text-transform: uppercase;
  &:hover {
    background: darken($dark-orchid, 10%);
  }
  &:focus {
    outline: none;
  }
}
.book-offer-back-button {
  @media (max-width: 992px) {
    display: none;
  }
}
