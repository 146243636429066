@import '../../variables.scss';

.footer {
  margin-bottom: 78px;
  margin-top: 18px;
  @media (max-width: 992px) {
    margin-top: 24px;
    margin-bottom: 36px;
  }

  .footer-wrapper {
    padding: 0 32px;

    .footer-wrapper-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .footer-wrapper-links-language-switcher, a, div {
        cursor: pointer;

        &:hover {
          color: #0056b3;
          text-decoration: none;
        }
      }


      @media (max-width: 992px) {
        justify-content: center;
      }
    }
  }
}
