@import '../../../variables.scss';

.workshop-item-container {
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}
.workshop-item-image {
  width: 240px;
  height: 160px;
  @media (max-width: 992px) {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
}
.workshop-item-button-container {
  width: 200px;
  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.workshop-item-text-above-button {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: $light2;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: none;
  }
}
.workshop-item-button {
  background-color: $dark-orchid;
  border: 1px solid $dark-orchid;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  height: 44px;
  color: white;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  &:hover {
    color: $white;
    background: darken($dark-orchid, 10%);
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    width: 174px;
    font-size: 11.5px;
    line-height: 15px;
  }
}
.workshop-item-button-grey {
  border: 1px solid $light6;
  color: $main;
  background-color: white;
  &:hover {
    color: $main;
    background: darken($white, 10%);
  }
  &:focus {
    outline: none;
  }
}
.workshop-item-button-disabled {
  &:hover {
    color: $main;
    background-color: white;
  }
  &:focus {
    color: $main;
    background-color: white;
  }
}
