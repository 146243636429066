@import "../../variables";

#loginErrorModalbox {
  margin: 0;
  padding: 0;
  height: 120px;
  width: 100%;
  max-width: 100% !important;
  position: relative;

  .modal-content {
    border-bottom: 4px solid red;
    border-radius: 0;

    .modal-body {
      margin: 0;
      padding: 0 240px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;


      div {
        .firstItem {
          font-family: 'ClanOT-Medium', sans-serif;
          font-size: 20px;
          line-height: 25px;
          color: $red;
          margin: 35px 0 0 0;
          padding: 0;

        }

        .secondItem {
          font-size: 15px;
          line-height: 20px;
          color: $main;
          margin: 4px 0 35px 0;
          padding: 0;
        }
      }

      .closeButton {
        .closeIcon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}


@media screen and (max-width: $xs) {
  #loginErrorModalbox {

    .modal-content {
      margin: 0;
      padding: 0;

      .modal-body {
        padding-left: 32px;
        padding-right: 32px;

        div {
          .firstItem {
            font-size: 16px;
            line-height: 28px;
            margin: 24px 0 0 0;
            max-width: 271px;
          }

          .secondItem {
            font-size: 12px;
            line-height: 16px;
            margin: 6px 0 24px 0;
            max-width: 340px;
          }
        }

        .closeButton {
          .closeIcon {
            width: 30px;
            height: 30px;
            color: $red;
          }
        }
      }
    }
  }
}

