@import '../../variables';

.fgi-modal {
  .modal-dialog {
    overflow: hidden;
    width: 600px;
    min-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottom-part {
    margin-top: 30px;
    height: 40px;
    width: 100%;
    background-image: url('../../assets/svgs/ModalBottom.svg');
  }

  .modal-header {
    position: relative;
    align-items: center;
    justify-content: center;
    border-bottom: 0;
    margin: 0;
    padding: 36px;
    display: flex;
    text-align: center;
  }

  .modal-title {
    position: relative;
    padding: 0 10px;
    width: auto;
    width: min-content;
    h4 {
      position: relative;
      z-index: 1;
      margin: 0;
      text-transform: uppercase;
      width: min-content;
    }

    span.colorBar {
      z-index: 0;
      display: block;
      width: 50%;
      height: 12px;
      position: absolute;
      top: 18px;
      left: 0;
      @media (max-width: 992px) {
        top: 12px;
      }

      &__yellow {
        background: $yellow;
      }

      &__green {
        background: $green;
      }

      &__blue {
        background: $blue;
      }

      &__orange {
        background: $dark-yellow;
      }
      &__dark-orchid {
        background: $dark-orchid;
      }
    }
  }

  .modal-body {
    padding: 0 32px 0 32px;

    .sub-description {
      text-align: center;
      padding: 0px 32px;
      display: flex;
      font-family: 'ClanOT-News';
      color: #666666;
      font-size: 14px;
      line-height: 22px;
    }

    .modal-list-container {
      padding: 0 25px;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        padding: 0;
      }

      .modal-list-container-item {
        width: 50%;
        padding: 10px 10px 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .modal-list-container-item-title,
        .modal-list-container-item-description {
          flex: 1;
        }

        .modal-list-container-item-description {
          padding: 10px 0;
        }
      }
    }
  }

  .form-control {
    min-height: 50px;
  }

  .modal-footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .back {
    position: absolute;
    left: 28px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
      fill: darken(#ccc, 10%);
      stroke: darken(#ccc, 10%);
    }
  }
  .close {
    position: absolute;
    top: 36px;
    right: 28px;
    font-weight: 300;
    font-size: 3rem;
    color: #ccc;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  &__description {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 32px;
  }

  @media (max-width: $md) {
    .form-row {
      flex-direction: column;
      display: flex;
    }

    .modal-dialog {
      width: 96%;
      min-width: 96%;
    }
  }
}

@media (max-width: $md) {
  .modal {
    padding: 0 !important;
  }
}

.small-modal {
  .modal-dialog {
    width: 440px;
    min-width: 440px;
  }
  @media (max-width: $xs) {
    .modal-dialog {
      width: 96%;
      min-width: 96%;
    }
  }

  &__ok-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 10px solid #e3e3e3;
    box-sizing: border-box;
    margin-bottom: 32px;
  }
}

.modal-textarea {
  min-height: 72px !important;
  @media (max-width: 992px) {
    min-height: 66px !important;
  }
}
