@import '../../variables';

.Container {
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 10%;

  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    max-width: 540px;
    width: 100%;
    padding: 48px 80px;

    .intro {
      max-width: 100%;

      .ItemWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
      }

      .icon-box {
        min-width: 44px;
        width: 44px;
        height: 44px;
        margin-right: 28.5px;
      }
    }

    .signupPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      p {
        margin-bottom: 35px;
      }

      .btn {
        padding: 18px 48px;
        background: $yellow;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          text-transform: uppercase;
        }

        img {
          padding-bottom: 2px;
          width: 30px;
        }
      }
    }

    .loginPart {
      display: flex;
      flex-direction: column;
      width: 250px;

      p {
        text-align: center;
        margin-bottom: 20px;
      }

      .btn {
        padding: 18px 48px;
        background: $yellow;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          text-transform: uppercase;
        }

        img {
          padding-bottom: 2px;
          width: 30px;
        }
      }
    }
  }

  .supportPart {
    background: #fbfbfa;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .titleWrapper {
      margin-top: 60px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .supportIcon-box {
        width: 26px;
        height: 26px;
        margin-right: 12px;
      }
    }

    .title {
      display: flex;
      align-items: center;
    }

    div.bottomSupportPart {
      text-align: center;
      padding: 0 15px;
      margin-bottom: 5px;

      a {
        color: $light2;
      }
    }

    .itemSupportWrapper {
      width: 60%;
      margin-bottom: 32px;
      display: flex;
      align-items: center;

      .icon-box2 {
        width: 30px;
        height: 30px;
        margin-right: 24px;
      }
    }
  }

  .chatContainer {
    position: fixed;
    right: 32px;
    // bottom: -228px;
    bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: white;

    .chat {
      width: 32px;
      height: 32px;
    }
  }
}

.warning {
  border: 2px solid #f00;
}

@media screen and (max-width: 1199px) {
  .Container {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 100px;

    .Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      padding: 56px 90px;
    }

    .supportPart {
      width: 100%;
      padding: 56px 90px;
    }
  }
}

@media screen and (max-width: 991px) {
  .Container {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 200px;

    .Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      padding: 30px;
    }

    .supportPart {
      width: 100%;
      padding: 50px;
    }
  }
}

@media screen and (max-width: 767px) {
  .Container {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 12px;

    .Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      padding: 50px;
    }

    .supportPart {
      width: 100%;
      padding: 56px;
    }

    .chatContainer {
      right: 16px;
      bottom: 16px;
      width: 52px;
      height: 52px;
      border-radius: 26px;

      .chat {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Container {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 12px;

    .Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      padding: 2rem;
    }

    .supportPart {
      width: 100%;
      padding: 2rem;
    }

    .chatContainer {
      right: 16px;
      bottom: 16px;
      width: 52px;
      height: 52px;
      border-radius: 26px;

      .chat {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .Container {
    padding: 180px 0 0 0;
    background-image: url(../../assets/images/coach_low.png);
    background-size: contain;
    margin: 0;
    width: 100%;

    .Wrapper {
      width: 100%;
      padding: 28px 51px 29px 51px;

      .intro {
        margin-top: 24px;

        .ItemWrapper {
          margin-bottom: 24px;
        }
      }

      .signupPart {
        margin-bottom: 30px;

        .btn {
          padding: 5px 20px 3px 28px;
        }

        p {
          margin-bottom: 29px;
        }
      }

      .loginPart {
        width: 200px;

        p {
          margin-bottom: 20px;
        }

        .btn {
          padding: 5px 20px 3px 28px;
        }
      }
    }

    .supportPart {
      padding: 0 0 22px 0;

      .titleWrapper {
        margin-top: 43px;
        margin-bottom: 30px;
      }

      .supportIcon-box {
        width: 26px;
        height: 26px;
        margin-right: 10px;
      }

      .icon-box {
        width: 32px;
        height: 32px;
        margin-right: 24.5px;
      }
    }

    .chatContainer {
      right: 16px;
      bottom: 16px;
      width: 52px;
      height: 52px;
      border-radius: 26px;

      .chat {
        width: 30px;
        height: 30px;
      }
    }
  }
}