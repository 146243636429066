@import '../../variables';

.admin-product-new-title-wrapper {
  position: relative;

  .admin-product-new-title-wrapper-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}
