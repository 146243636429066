@import '../../variables';

.coach-finder {
  &__modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .coach-finder-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    margin-left: -24px;
  }
}
