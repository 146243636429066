@import '../../variables.scss';

.custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  height: 100px;
  background: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  .navbar-brand img {
    height: 60px !important;
  }
  @media (max-width: 992px) {
    height: 64px;
    .navbar-brand img {
      height: 48px !important;
    }
  }
  
  .custom-navbar-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      height: calc(100vh - 64px);
    }

    .custom-navbar-menu {
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 991px) {
        width: 236px;
        align-items: flex-start;
        padding: 20px 12px 16px 12px;
      }
    }
    
    .custom-navbar-menu a div {
      margin: 8px 5px;
    }
  }
}
