@import '../../variables';

#signupModalbox {
  .modal-content {
    width: 600px;

    .modal-row {
      display: flex;
      margin-top: 24px;
      @media (max-width: $md - 1) {
        flex-direction: column;
      }
    }
    .modal-header {
      position: relative;
      align-items: center;
      justify-content: center;
      border-bottom: 0;

      .modal-title {
        position: relative;
        padding: 0 10px;
        width: auto;

        h4 {
          position: relative;
          z-index: 1;
          margin: 0;
          font-size: 30px;
        }
        span.orangeBar {
          z-index: 0;
          display: block;
          width: 50%;
          height: 12px;
          background: $yellow;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;

      form {
        padding: 0 32px;
        .signupDesc {
          margin-top: 36px;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666;
        }
        .checkboxLayout {
          margin-bottom: 32px;
          display: flex;
          justify-content: center;
          align-items: center;

          .custom-control {
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .formFields {
          .rowItems {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .formItems {
              width: 48%;

              .formText {
                margin-bottom: 8px;
                font-size: 13px;
                font-weight: bold;
              }

              .form-control {
                height: 48px;
              }

              .itemWarning {
                position: absolute;
                bottom: -20px;
                font-size: 11px;
                color: $red;
              }
            }

            .emailItems {
              width: 100%;

              .formText {
                margin-bottom: 8px;
                font-size: 13px;
                font-weight: bold;
              }

              .form-control {
                height: 48px;
              }

              .itemWarning {
                position: absolute;
                bottom: -20px;
                font-size: 11px;
                color: $red;
              }
            }
          }
        }

        .termWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          margin-top: 12px;

          .termCheckbox {
            margin-right: 12px;
          }

          .termsText {
            width: 60%;
            color: $main;

            p {
              margin: 0;
              color: $main;
              font-size: 13px;
              line-height: 16px;

              a {
                color: $main;
                text-decoration: underline;
              }
            }

            &.error {
              p {
                color: $red;

                a {
                  color: $red;
                }
              }
            }
          }
        }

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .bottomPart {
        margin-top: 40px;
        height: 40px;
        width: 100%;
        background-image: url('../../assets/svgs/ModalBottom.svg');
      }
    }
  }
}

@media screen and (max-width: $md) {
  #signupModalbox {
    margin: 0;

    .modal-content {
      width: 100%;
      height: 100%;

      .modal-header {
        margin-top: 22px;
        padding-bottom: 28px;

        .modal-title {
          h4 {
            font-size: 24px;
          }
        }
      }

      .modal-body {
        form {
          .signupDesc {
            margin: 0;
            padding: 0;
            font-size: 12px;
            text-align: center;
          }

          .checkboxLayout {
            margin-top: 24px;
            margin-bottom: 0;

            .checkbox {
              cursor: pointer;
              width: 32px;
              height: 32px;
            }
          }

          .formFields {
            margin: 0;
            padding: 0;
            .rowItems {
              margin: 0;
              padding: 0;
              display: block;
              .formItems {
                width: 100%;
                margin-bottom: 4px;
                margin-top: 11px;

                .formText {
                  font-size: 10px;
                  line-height: 13px;
                  color: $main-dark;
                }

                .form-control {
                  height: 40px;
                }
              }
              .emailItems {
                .formText {
                  font-size: 10px;
                  line-height: 13px;
                  color: $main-dark;
                }
                .form-control {
                  height: 40px;
                }
              }
            }
          }

          .termWrapper {
            .termCheckbox {
              .checkbox {
                cursor: pointer;
                width: 32px;
                height: 32px;
              }
            }

            .termsText {
              width: 70%;

              p {
                font-size: 10px;
                line-height: 14px;
              }
            }
          }

          .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 20px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 0px;
            margin-bottom: 0;
            margin-top: 26px;

            img {
              width: 25px;
              padding-bottom: 2px;
            }
          }

          .bottomPart {
            margin-top: 28px;
          }
        }
      }
    }
  }
}

.sign-up-modal {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  &__invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  &__term {
    margin-bottom: 36px;
  }
}
