@import '../../variables';

#loginModalbox {
  top: 50%;
  transform: translateY(-50%);

  .modal-content {
    width: 440px;

    .modal-header {
      position: relative;
      align-items: center;
      justify-content: center;
      border-bottom: 0px;

      .modal-title {
        position: relative;
        padding: 0 10px;
        width: auto;

        h4 {
          position: relative;
          z-index: 1;
          margin: 0;
        }
        span.orangeBar {
          z-index: 0;
          display: block;
          width: 50%;
          height: 12px;
          background: $yellow;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      padding: 0;

      form {
        padding: 0 32px;
        .loginDesc {
          display: flex;
          justify-content: center;
          font-size: 15px;
          color: $main;
        }

        .formFields {
          margin-top: 36px;

          .loginFormItems {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            position: relative;

            .formText {
              margin-bottom: 8px;
              font-size: 13px;
              font-weight: bold;
            }

            .form-control {
              height: 48px;
            }

            .itemWarning {
              position: absolute;
              bottom: -20px;
              font-size: 11px;
              color: $red;
            }
          }
        }

        .loginSubmit {
          margin-top: 12px;
          width: 124px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 32px;

          img {
            padding-bottom: 2px;
          }
        }
      }

      .loginBottomPart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 32px;

        .loginBottomPart-reset-password {
          text-decoration: underline;
          cursor: pointer;
        }

        p {
          width: 95%;
          font-size: 14px;
          text-align: center;
          color: #666;

          a {
            color: #666;
            text-decoration: underline;
          }
        }
      }
    }
    .bottomPart {
      margin-top: 40px;
      height: 40px;
      width: 100%;
      background-image: url('../../assets/svgs/ModalBottom.svg');
    }
  }
}

@media screen and (max-width: $xs) {
  #loginModalbox {
    width: 100%;
    margin: 0;
    top: 0;
    transform: none;

    .modal-content {
      width: 100%;
      margin: 0;

      .modal-header {
        margin-top: 22px;
        padding-bottom: 28px;

        .modal-title {
          h4 {
            font-size: 24px;
          }

          span.orangeBar {
            width: 80%;
            height: 8px;
          }
        }
      }

      .modal-body {
        form {
          .loginDesc {
            font-size: 12px;
            line-height: 16px;
            margin: 0;
            padding: 0;
          }

          .formFields {
            padding: 0;
            margin: 24px 0 0 0;
            .loginFormItems {
              margin-bottom: 15px;
              .formText {
                margin-bottom: 4px;
                font-size: 10px;
                line-height: 13px;
              }
              .form-control {
                height: 40px;
              }
            }
          }
        }

        .loginSubmit {
          width: 96px !important;
          font-size: 11.5px !important;
          margin-top: 10px;
          height: 38px !important;
          padding-left: 20px !important;

          img {
            width: 25px;
            padding-bottom: 2px;
          }
        }

        .loginBottomPart {
          margin-top: 24px;

          p {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #666;
            padding: 0;
          }
        }
      }
    }

    .bottomPart {
      margin-top: 20px !important;
    }
  }
}

.login-modal {
  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
