@import '../../../variables.scss';

.success-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success-modal-check {
  width: 160px;
  height: 160px;
  margin-bottom: 40px;
  @media (max-width: 992px) {
    width: 120px;
    height: 120px;
    margin-bottom: 28px;
  }
}
.success-modal-text {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $main;
  padding: 0 30px;
  @media (max-width: 992px) {
    padding: 0 24px;
    font-size: 12px;
    line-height: 16px;
  }
}
.success-modal-button {
  height: 44px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-family: 'ClanOT-Medium';
  color: white;
  border: 1px solid $dark-orchid;
  background-color: $dark-orchid;
  margin-top: 32px;
  padding: 14px 32px;
  text-transform: uppercase;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  &:hover {
    background: darken($dark-orchid, 10%);
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    height: 36px;
    font-size: 11.5px;
    line-height: 15px;
    padding: 11px 32px;
  }
}
