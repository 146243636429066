@import '../../../variables.scss';

.date-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  @media (max-width: 992px) {
    align-self: flex-start;
    align-items: flex-start;
  }
}
.date-component-text {
  white-space: nowrap;
  text-align: center;
}
.date-component-day {
  font-size: 30px;
  line-height: 38px;
  color: $dark-orchid;
  text-transform: uppercase;
  @media (max-width: 992px) {
    align-items: center;
    font-size: 19px;
    line-height: 24px;
  }
}

.date-component-date {
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: $main-dark;
  margin-top: 8px;
  @media (max-width: 992px) {
    margin-top: 0;
    font-size: 13px;
    line-height: 16px;
  }
}

.date-component-week {
  font-size: 14px;
  line-height: 22px;
  color: $main;
  margin-top: 8px;
  @media (max-width: 992px) {
    display: none;
  }
}
