@import '../../variables';

.admin-products-title-wrapper {
  position: relative;

  .admin-products-title-wrapper-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  min-height: 788px;
  margin-top: 24px;

  .admin-products-products-list {
    padding: 37px 40px;
    width: 100%;

    .admin-products-table {
      width: 100%;

      .admin-products-table-head-row {
        border-bottom: 1px solid $light6;

        th {
          padding-bottom: 13px;
        }
      }

      .admin-products-table-body-row {
        border-bottom: 1px solid $light6;

        td {
          padding: 10px 0;
        }
      }
    }
  }
}
