@import '../../variables';

.checkbox-container {
  display: flex;
  align-items: center;

  .checkbox {
    border: 1px solid $light3;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 992px) {
      width: 32px;
      height: 32px;
    }

    .checkinner {
      width: 16px;
      height: 16px;
      border: $yellow solid 8px;
      @media (max-width: 992px) {
        width: 14px;
        height: 14px;
        border: $yellow solid 7px;
      }
    }
  }

  .checkbox-label {
    cursor: pointer;
    margin-left: 12px;
    color: $main;
    margin-right: 15px;
  }

  &-error {
    .checkbox {
      border: 1px solid $red;
    }

    .checkbox-label {
      color: $red;
    }
  }
}
