@import '../../variables.scss';

.review-coaches-modal {
  .modal-dialog {
    @media (min-width: 993px) {
      width: 800px;
      min-width: 800px;
    }

    .review-coaches-modal-coaches-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      @media (max-width: 992px) {
        flex-direction: column;
      }

      .review-coaches-modal-coaches-container-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        @media (max-width: 992px) {
          flex-direction: row;
          padding: 0;
          align-items: flex-start;
          margin-top: 50px;
        }

        .review-coaches-modal-coaches-container-item-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 370px;
          @media (max-width: 992px) {
            align-items: flex-start;
            height: 150px;
            padding-left: 10px;
          }

          .review-coaches-modal-coaches-container-item-info-text {
            flex: 1;
            text-align: center;
            @media (max-width: 992px) {
              text-align: left;
              flex: none;
            }

            .review-coaches-modal-coaches-container-item-info-text-bio-container {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              @media (max-width: 992px) {
                display: none;
                align-items: baseline;
                margin-top: 6px;
              }

              .review-coaches-modal-coaches-container-item-info-text-bio-label {
                @media (max-width: 992px) {
                  width: 60px;
                }
              }

              .review-coaches-modal-coaches-container-item-info-text-bio-value {
                max-width: 200px;
                @media (max-width: 992px) {
                  max-width: 125px;
                }
              }

              .review-coaches-modal-coaches-container-item-info-text-bio-email {
                max-width: 200px;
                color: $main;
                @media (max-width: 992px) {
                  max-width: 125px;
                }
              }
            }
          }

          .review-coaches-modal-coaches-container-item-info-buttons-container {
            display: none;
            @media (max-width: 992px) {
              display: flex;
              align-items: center;
            }

            .review-coaches-modal-coaches-container-item-info-buttons-container-email {
              color: $main;
              margin-right: 10px;
              padding: 13px 16px 11px 16px;
              border: 1px solid $light6;
              text-decoration: none;
              text-transform: uppercase;
            }
          }

          .review-coaches-modal-coaches-container-item-info-button {
            margin: 16px 0;
          }
        }
      }
    }
  }
}
