@import '../../../variables';

.sign-off-dialog-modal-text {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: $main;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.sign-off-dialog-modal-button-container {
  display: flex;
  flex-direction: column;
  height: 155px;
  width: auto;
  justify-content: space-around;
  align-items: center;
  margin-bottom: -25px;
  @media (max-width: 992px) {
    height: 135px;
  }
}
.sign-off-dialog-modal-button {
  background-color: $dark-orchid;
  border: 1px solid $dark-orchid;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  height: 44px;
  width: 94px;
  color: white;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: $white;
    background: darken($dark-orchid, 10%);
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    height: 36px;
  }
}

.sign-off-dialog-modal-button-grey{
    border: 1px solid $light6;
    color: $main;
    background-color: white;
    &:hover {
      color: $main;
      background: darken($white, 10%);
    }
    &:focus {
      outline: none;
    }
}