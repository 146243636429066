@import '../../../variables';

.main-info-container {
  display: flex;
}

.main-info-image {
  height: 152px;
  width: 152px;
  border-radius: 100%;
  margin-right: 32px;
  @media (max-width: 992px) {
    height: 128px;
    width: 128px;
    margin-left: -60px;
    margin-right: 16px;
  }
}
.main-info-title {
  font-family: 'ClanOT-Medium';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 14px;
  padding-top: 12px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}
.main-info-first-column {
  font-family: 'ClanOT-Medium';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: $main-dark;
  width: 95px;
  @media (max-width: 992px) {
    font-size: 10px;
    line-height: 14px;
    width: 60px;
  }
}

.main-info-second-column {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $main;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}
.main-info-title-table {
  margin: 0 12px;
}
