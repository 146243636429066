@import '../../../../variables.scss';

.moderator-finden-middle-item {
  background-color: $white;
  display: flex;
  padding: 100px 72px 130px 72px;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    padding: 70px 0;
  }

  .moderator-finden-middle-item-left-container {
    padding: 43px 50px 0 70px;
    flex: 1;
    @media (max-width: 992px) {
      padding: 30px 48px 0 48px;
    }

    .moderator-finden-middle-item-left-container-description {
      margin-top: 10px;
    }
  }

  .moderator-finden-middle-item-right-container {
    background-image: url('../../../../assets/images/mf-image.png');
    flex: 2;
    height: 300px;
    background-size: cover;
    margin-right: 70px;
    @media (max-width: 992px) {
      margin: 0;
      height: 180px;
    }
  }
}
