.hero {
  margin-top: 24px;
  width: 100%;
  padding: 64px 0 40px 73px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 992px) {
    margin-top: 16px;
    padding: 50px 0 55px 8px;
  }
}
