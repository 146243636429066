@import '../../../variables';

.info-part-of-modal-hr {
  width: 100%;
  border-width: 3px;
  @media (max-width: 992px) {
    border-width: 0;
  }
}
.container-info-part-of-modal {
  display: flex;
  flex-direction: column;
}
.info-part-of-modal-text {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $main;
  padding: 50px;
  @media (max-width: 992px) {
    padding: 24px 0;
    font-size: 12px;
    line-height: 16px;
  }
}

.info-part-of-modal-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  justify-content: space-between;
  @media (max-width: 992px) {
    height: 105px;
  }
}
.info-part-of-modal-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.info-part-of-modal-buttons-container-edit {
  height: 190px;
}
.info-part-of-modal-text-above-button {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  justify-content: space-around;
  text-transform: uppercase;
  color: $light2;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: none;
  }
}
