@import '../../../../variables';

.leadership-top-item-lto-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 56px;
  margin-left: -24px;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .leadership-top-item-right-button {
    margin-left: 16px;
    @media (max-width: 992px) {
      margin: 16px 0 0 0;
    }
  }
}
