@import '../../../variables';

.choose-topic-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-topic-title {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 13px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.choose-topic-image {
  width: 104px;
  height: 104px;
  border-radius: 100%;
  @media (max-width: 992px) {
    font-size: 96px;
    line-height: 96px;
  }
}
.choose-topic-image-container {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 992px) {
    margin-bottom: 28px;
  }
}
.choose-topic-hr {
  width: 100%;
  border-width: 3px;
  @media (max-width: 992px) {
    border-width: 0;
  }
}
.choose-topic-subtitle {
  font-family: 'ClanOT-News';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 15px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.choose-topic-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 992px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.choose-topic-button {
  height: 44px;
  width: 245px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-family: 'ClanOT-Medium';
  color: $dark-orchid;
  background-color: inherit;
  border: 1px solid $light6;
  margin: 15px 10px;
  text-transform: uppercase;
  &:hover {
    background: darken(white, 10%);
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    height: 36px;
    font-size: 11px;
    line-height: 14px;
    margin: 8px 0;
  }
}

.choose-topic-button-active {
  color: white;
  border: 1px solid $dark-orchid;
  background-color: $dark-orchid;
  &:hover {
    background: darken($dark-orchid, 10%);
  }
  &:focus {
    outline: none;
  }
}
.choose-topic-bottom-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: row-reverse;
    height: auto;
    margin-top: 24px;
    width:100%
  }
}
