@import '../../variables.scss';

.settings {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  @media (max-width: 992px) {
    margin-top: 16px;
    flex-direction: column;
  }

  .settings-info-container {
    display: flex;
    flex-direction: column;
    flex: 2;
    background: $white;
    padding: 43px 37px 213px 37px;
    @media (max-width: 992px) {
      padding: 32px 16px 42px 16px;
    }

    .settings-mobile-column {
      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
      }
    }

    .settings-info-container-hr {
      width: 100%;
      margin: 40px 0;
    }

    .settings-info-container-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 992px) {
        position: absolute;
        right: 16px;
      }
    }
  }

  .settings-password-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: $light4;
    padding-top: 45px;
    @media (max-width: 992px) {
      padding: 50px 70px;
    }
  }
}
