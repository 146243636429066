@import '../../variables';

.file-input-container {
  width: 240px;
  min-width: 240px;
  border: 1px solid $light3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &-product {
    height: 69px;
  }

  &-logo {
    height: 85px;
  }

  &-pdf {
    height: 69px;
  }

  &-red {
    border: 1px solid $red;
  }

  &-green {
    border: 1px solid $green;
  }

  .file-input {
    width: 100%;
    height: 100%;
    color: transparent;
    position: absolute;
    cursor: pointer;
  }

  .file-input-icon {
    background: url('../../assets/svgs/FileUploadIcon.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }

  .file-input-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 143px;
  }

  .file-input-image-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: $white;

    .file-input-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .file-input-pdf-open {
      position: absolute;
      cursor: pointer;
      width: 150px;
      left: calc(50% - 75px);
      top: calc(50% - 10px);
      text-align: center;
    }
  }
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-input-modal {
  .modal-dialog {
    width: 704px;
    min-width: 704px;
  }

  .file-input-modal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
  }
}