@import '../../../../variables.scss';

.landing-page-coach-item {
  background-color: $light;

  .landing-page-coach-item-title {
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      padding: 40px 20px;
    }
  }

  .landing-page-coach-item-main-container {
    display: flex;
    flex-direction: row;
    padding: 0 150px 70px 150px;
    justify-content: space-around;
    @media (max-width: 992px) {
      flex-direction: column;
      padding: 0 40px 70px 40px;
    }

    .landing-page-coach-item-main-container-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      @media (max-width: 992px) {
        padding: 10px;
      }

      .landing-page-coach-item-main-container-item-top-container {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 300px;
        height: 400px;
        @media (max-width: 992px) {
          height: 300px;
        }
      }

      .landing-page-coach-item-main-container-item-bottom-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
