@import 'variables';

@font-face {
  font-family: 'ClanOT-News';
  src: url('./assets/fonts/ClanOT-News.otf'); /* IE9 Compat Modes */
  font-display: swap;
}

@font-face {
  font-family: 'ClanOT-News';
  src: url('./assets/fonts/ClanOT-News.eot');
  src: url('./assets/fonts/ClanOT-News.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/ClanOT-News.woff2') format('woff2'),
    url('./assets/fonts/ClanOT-News.woff') format('woff'),
    url('./assets/fonts/ClanOT-News.ttf') format('truetype'),
    url('./assets/fonts/ClanOT-News.svg#ClanOT-News') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ClanOT-Medium';
  src: url('./assets/fonts/ClanOT-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/ClanOT-Medium.otf') format('opentype'),
    url('./assets/fonts/ClanOT-Medium.woff') format('woff'),
    url('./assets/fonts/ClanOT-Medium.ttf') format('truetype'),
    url('./assets/fonts/ClanOT-Medium.svg#ClanOT-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'ClanOT-News', sans-serif;
  background: #e5e5e5;
}

form {
  display: flex;
  flex-direction: column;
}

.paper {
  background: linear-gradient(
      180deg,
      rgba(241, 243, 240, 0.1) 0%,
      rgba(255, 255, 255, 0) 19.73%
    ),
    $white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  padding: 25px;
}

select {
  appearance: none;
  padding-left: 30px !important;
  background: url('./assets/svgs/select-arrow.svg') no-repeat 10px;
}

.strong {
  font-weight: 700;
  border-bottom: 2px solid $yellow;
}

.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.custom-nav-item {
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 45px;
  align-items: center;
}

.custom-nav-item .nav-item-text {
  border-bottom: 4px solid transparent;
  margin-left: 10px;
  transform: translateY(4px);
}

.custom-nav-item:hover .nav-item-text {
  border-bottom: 4px solid $yellow;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}

.container-with-margins {
  margin-top: 116px;
  margin-bottom: 16px;
  .row {
    gap: 24px;
    margin-bottom: 9px;
  }
  .col {
    padding: 0;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    .row {
      gap: 0px;
      width: inherit;
    }
  }
}

.primary-text {
  font-family: 'ClanOT-News', sans-serif;
  color: $main;
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.primary-text-medium {
  font-family: 'ClanOT-Medium', sans-serif;
  color: $main-dark;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.secondary-text {
  color: $light2;
  font-size: 12px;
  line-height: 16px;
  @media (max-width: 992px) {
    font-size: 10px;
    line-height: 14px;
  }
}

.large-text {
  color: $main-dark;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 992px) {
    font-size: 13px;
    line-height: 20px;
  }
}

.label-text {
  font-family: 'ClanOT-Medium', sans-serif;
  font-size: 13px;
  color: $main-dark;
  line-height: 16px;
}

.title-page {
  color: $main-dark;
  font-size: 30px;
  line-height: 38px;
  @media (max-width: 992px) {
    font-size: 19px;
    line-height: 24px;
  }
}

.title-section {
  text-transform: uppercase;
  color: $main-dark;
  font-size: 20px;
  line-height: 20px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.title-snippet {
  color: $main-dark;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 100%;
  }
}

.title-article {
  text-transform: uppercase;
  color: $main-dark;
  font-size: 16px;
  line-height: 16px;
  @media (max-width: 992px) {
    font-size: 13px;
  }
}

.title-modal {
  color: $main-dark;
  font-size: 30px;
  line-height: 30px;
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 24px;
  }
}

.title-box {
  color: $main-dark;
  font-size: 24px;
  line-height: 24px;
  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 18px;
  }
}

.title-content {
  font-family: 'ClanOT-Medium', sans-serif;
  color: $main-dark;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  @media (max-width: 992px) {
    font-size: 10px;
    line-height: 10px;
  }
}

.title-content-2 {
  font-family: 'ClanOT-Medium', sans-serif;
  color: $main-dark;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 12px;
  }
}

.title-pro-nr {
  font-family: 'ClanOT-Medium', sans-serif;
  color: $main-dark;
  font-size: 24px;
  line-height: 30px;
  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 20px;
  }
}

.misc-quote {
  color: $main;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.text-button-big {
  font-size: 15px;
  font-family: 'ClanOT-Medium', sans-serif;
  line-height: 19px;
  @media (max-width: 992px) {
    font-size: 12.5px;
    line-height: 16px;
  }
}

.text-button-medium {
  font-size: 13px;
  font-family: 'ClanOT-Medium', sans-serif;
  line-height: 16px;
  @media (max-width: 992px) {
    font-size: 11.5px;
    line-height: 16px;
  }
}

.text-button-small {
  font-size: 13px;
  font-family: 'ClanOT-Medium', sans-serif;
  line-height: 16px;
  @media (max-width: 992px) {
    font-size: 11px;
    line-height: 16px;
  }
}

.text-nav {
  font-family: 'ClanOT-Medium', sans-serif;
  color: $main-dark;
  font-size: 13px;
  line-height: 13px;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.description {
  @extend .primary-text;
}

.big-title {
  font-size: 24px;
  color: $main-dark;
  text-transform: uppercase;
  @media (max-width: 992px) {
    font-size: 16px;
  }
}

.form-label {
  font-family: 'ClanOT-Medium', sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: $main-dark;
  @media (max-width: 992px) {
    font-size: 10px;
    line-height: 13px;
  }
}

.text-small-1 {
  font-family: 'ClanOT-News', sans-serif;
  color: $light2;
  font-size: 11px;
  line-height: 11px;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 9px;
  }
}

.hidden {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.dropdown-toggle::before {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.show {
  .dropdown-toggle::before {
    border-top: 0;
    border-bottom: 0.3em solid;
  }
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border: 0;
}

.navbar-toggler {
  border: 0;
  .navbar-toggler-icon {
    background-image: url(./assets/images/Trigger.png);
    width: 40px;
    height: 40px;
  }
}

.dropdown-menu {
  left: -9.8rem;
  top: 40px;
  border-top: 0;
  border-radius: 0;
  border-bottom: 1px solid $light5;
  border-right: 0;
  border-left: 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: $md) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

.dropdown-menu {
  .custom-nav-item {
    margin-right: 35px !important;
    margin-left: 35px !important;
    margin-bottom: 12px;
    margin-top: 12px;
  }
}

.hidden-on-desktop-menu {
  display: none;
}

@media (max-width: 992px) {
  .container {
    z-index: 10;
  }
  .navbar-nav {
    z-index: 10000 !important;
  }
  .hidden-on-mobile-menu {
    display: none;
  }
  .hidden-on-desktop-menu {
    display: block;
  }
  .navbar-collapse {
    position: static;
    align-items: flex-start;
    margin-top: -2px;
    margin-right: -16px;
    margin-left: -16px;
    background: rgba($white, 0.98);
    width: 100%;
    .navbar-nav {
      .align-self-center {
        align-self: flex-start !important;
      }
      .custom-nav-item {
        margin-right: 0;
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: 18px;
      }
    }
  }
}

@media (max-width: $xs) {
  .container.container-with-margins {
    padding: 0;
    .row,
    .row.white,
    .col,
    .col-with-margin {
      padding: 0;
      margin: 0;
    }
  }
}

.modal-content {
  border: none;
}

.modal-back-arrow {
  position: absolute;
  margin-top: -65px;
}

.color-underline {
  z-index: -1;
  display: block;
  width: 100%;
  height: 7px;
  position: absolute;
  top: 18px;
  left: 0;

  &-yellow {
    background: $yellow;
  }

  &-blue {
    background: $blue;
  }

  &-orange {
    background: $dark-yellow;
  }

  &-green {
    background: $green;
  }
  &-dark-orchid {
    background: $dark-orchid;
  }
}

.checkboxContainer {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-control {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}
