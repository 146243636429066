@import '../../variables';

.page-title {
  position: relative;
  padding: 0 8px;
  width: auto;
  margin-top: 23px;
  margin-bottom: 3px;

  .page-color-bar {
    z-index: 0;
    display: block;
    width: 20%;
    height: 10px;
    position: absolute;
    top: 20px;
    left: 0;
    margin-top: 2px;

    &-yellow {
      background: $yellow;
    }

    &-blue {
      background: $blue;
    }

    &-orange {
      background: $dark-yellow;
    }

    &-green {
      background: $green;
    }
    &-dark-orchid {
      background: $dark-orchid;
    }
  }

  .page-text-wrapper {
    position: relative;
    z-index: 1;
    margin: 0;
    text-transform: uppercase;
  }

  @media (max-width: $xs) {
    height: auto;
    padding-left: 4px;
    padding-top: 8px;

    .page-color-bar {
      height: 6px;
    }
  }

  @media (max-width: 992px) {
    background-size: cover;
  }
}
