@import '../../variables';

@import '../../variables';

.admin-checkbox {
  display: flex;
  align-items: center;
}

.admin-checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.admin-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.admin-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid $yellow;
}

/* When the checkbox is checked, add a blue background */
.admin-checkbox-container input:checked ~ .admin-checkbox-checkmark {
  background-color: $yellow;
}

.admin-checkbox-container input:disabled ~ .admin-checkbox-checkmark {
  background-color: $light3;
  border: 1px solid $light6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.admin-checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.admin-checkbox-container input:checked ~ .admin-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.admin-checkbox-container .admin-checkbox-checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.admin-checkbox-container input:disabled ~ .admin-checkbox-checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid $main;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.admin-checkbox-text-container {
  margin-left: 42px;
}
