@import '../../variables';
/* Confirm email address */

#confirmModalbox {
  .modal-content {
    width: 440px;

    .modal-header {
      position: relative;
      align-items: center;
      justify-content: center;
      border-bottom: 0;

      .modal-title {
        position: relative;
        padding: 0 10px;
        width: auto;

        h4 {
          position: relative;
          z-index: 1;
          margin: 0;
        }
        span.orangeBar {
          z-index: 0;
          display: block;
          width: 50%;
          height: 12px;
          background: $yellow;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        font-weight: 300;
        font-size: 3rem;
        color: #ccc;
        padding: 0;
        margin-top: 5px;
        margin-right: 1rem;
        display: none;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;

      .emailLogo {
        align-self: center;
        width: 170px;
        height: 120px;
      }

      .confirmEmailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 36px;
        position: relative;
        width: 100%;
        font-size: 14px;
        line-height: 22px;

        p {
          width: 80%;
          font-size: 15px;
          text-align: center;
          color: #666;
        }
      }

      .confirmSubmit {
        width: 207px !important;
        height: 40px !important;
        border: 1px solid #efefef;
        background-color: #fff !important;
        color: $light2;
        font-size: 13px;
        margin-top: 32px;
      }

      .bottomPart {
        margin-top: 40px;
        height: 40px;
        width: 100%;
        background-image: url('../../assets/svgs/ModalBottom.svg');
      }
    }
  }
}

@media screen and (max-width: $xs) {
  #confirmModalbox {
    width: 100%;
    margin: 0;
    top: 0;
    transform: none;

    .modal-content {
      width: 100%;
      padding: 0;
      margin: 0;

      .modal-header {
        padding: 0;
        margin-top: 40px;
        font-size: 30px;
        line-height: 38px;

        .modal-title {
          h4 {
            font-size: 24px;
          }

          span.orangeBar {
            height: 8px;
          }
        }
      }

      .modal-body {
        .emailLogo {
          width: 113px;
          height: 80px;
          margin-top: 28px;
        }

        .confirmEmailWrapper {
          margin-top: 28px;
          p {
            font-size: 12px;
            line-height: 16px;
            margin: 0 0 24px 0;
            padding: 0;
          }
        }

        .confirmSubmit {
          width: 166px !important;
          height: 32px !important;
          border: 1px solid #efefef;
          background-color: #fff !important;
          color: $light2;
          font-size: 11px;
          line-height: 14px;
          margin: 0;
        }
      }
    }
  }
}
